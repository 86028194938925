<template>
  <div>
    Redirect Auth Params.
  </div>
</template>

<script>
import userController from "@/middleware/userController.js";

export default {
  mounted() {
    const token = this.$route.query.token;
    const turmaId = this.$route.query.turma;
    const splited = token.split(" ");
    const tokenType = splited[0];
    const accessToken = splited[1];
    const tokenPayload = {
      tokenType: tokenType,
      accessToken: accessToken
    };
    this.$store.commit("aluno/setTokenAuth", tokenPayload);
    localStorage.setItem("APPDATURMA_TURMA_ATIVA", turmaId);
    localStorage.setItem(
      "JWT_APPDATURMA_ALUNO/token",
      tokenPayload.accessToken
    );
    localStorage.setItem("JWT_APPDATURMA_ALUNO/type", tokenPayload.tokenType);
    this.loadDashboardDados();
  },
  methods: {
    loadDashboardDados() {
      userController.getUserData().then(() => {
        this.$router.push("/aluno/dashboard");
      });
    }
  }
};
</script>

<style></style>
